/*
* BASIC ROOT ELEMENTS
*
* Setting up the container, header, footer etc
*/

#root {
    height: 100vh;
}

html {
    height: 100%;
}

.snackbar-tracker img {
    padding: 20px;
}

.snackbar-tracker {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
}

.SnackbarContent-root.SnackbarItem-contentRoot.snackbar-tracker-wrapper {
    background-color: #2196f3;
}

body {
    height: 100%;
    margin: 0;
    background-repeat: no-repeat;
    background-attachment: fixed;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

main {
    padding-top: 50px;
}

/* Works on Firefox */
* {
scrollbar-width: thin;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
width: 6px;
height: 6px;
}

*::-webkit-scrollbar-thumb {
    background-color: rgba(89, 89, 89, 0.5);
  }

.operations-display {
    position: fixed;
    width: 100%;
    top: 0px;
}

.operations-text {
    position: fixed;
    margin-left: 70%;
    top: 10px;
}

.App {
    text-align: center;
}

.navbar-brand {
    margin-left: 50%;
}

.App-title {
    font-size: 1.5em;
}

.welcome-banner {
    text-align: center;
}

.App-link {
    color: #61dafb;
}

.alert-pre {
    word-wrap: break-word;
    word-break: break-all;
    white-space: pre-wrap;
}

.navbar-expand-md .navbar-nav .nav-link {
    padding-left: 30px !important;
}

.table-wrapper {
    padding: 30px;
}

.MuiSnackbar-root {
    margin-left: 100px !important
}

p {
    margin-bottom: 0 !important;
}

.table-cell {
    max-width: 0;
    min-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    z-index: 1;
}

.id-table-cell {
    max-width: 0;
    min-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.status-cell-green p {
    background-color: #187900;
    color: white;
}

.status-cell-red p {
    background-color: #c61400;
    color: white;
}

.status-cell-grey p {
    background-color: #777777;
    color: white;
}

.status-cell-dormant p {
    background-color: transparent;
    border-color: #187900;
    border-style: solid;
    border-width: thin;
}

.status-cell-building p {
    background-color: #00698f;
    animation: status-cell-building 2s infinite;
    color: white;
}

.status-cell-building p,
.status-cell-red p,
.status-cell-green p,
.status-cell-grey p,
.status-cell-dormant p {
    margin-bottom: 0;
    text-align: center;
    padding: 5px;
    border-radius: 25px;
    width: 100px;
    font-size: 0.7em;
}

@keyframes status-cell-building {
    0% {
        background-color: rgba(0, 69, 143, 1);
    }

    50% {
        background-color: rgba(0, 69, 143, 0.2);
    }

    100% {
        background-color: rgba(0, 69, 143, 1);
    }
}

/* override table row select color*/
.MuiTableRow-root.Mui-selected,
.MuiTableRow-root.Mui-selected:hover {
    background-color: #77777720 !important;
}

.action-button-container {
    display: flex;
    width: 100%;
    margin: 5px;
    flex-wrap: wrap;
    justify-content: center;
}

.sub-action-button-container {
    text-align: left;
    float: right;
    display: inline;
    vertical-align: text-bottom;
}

.action-button-container .MuiButtonBase-root,
.sub-action-button-container .MuiButtonBase-root {
    text-wrap: nowrap;
    margin: 5px;
    font-size: 0.8rem;
}

.au-btn {
    margin: 5px;
}

.underline {
    text-decoration: underline;
}

.table-search-box {
    width: 500px;
    border-radius: 25px;
    margin-bottom: 10px;
    padding-left: 10px;
}

.refresh-csv-btn {
    float: right;
    margin-left: auto;
}

.refresh-csv-btn button {
    margin-left: 10px;
}

h2 {
    margin-bottom: 20px !important;
    margin-top: 20px !important;
}

.input-text {
    width: 600px !important;
}

.attribute-table-container {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
}

.MuiGrid-root .MuiFormLabel-root {
    color: black;
}

.inputForm {
    margin-top: 30px;
}

.tick {
    margin: 50px;
}

.formRow {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.mandatory-info {
    margin-top: 10px !important;
    margin-bottom: 50px !important;
    font-style: italic;
}

.console-output-box #output {
    font-family: 'Courier New', Courier, monospace;
}

.md-content p + * {
    margin-top: 1rem;
}
.md-content h3 {
    font-size: 1rem;
    margin-top: 1.5rem;
}

/*
* TABLES
*/

.empty-table-row {
    height: 30px;
}

.enhanced-table-table {
    min-width: 750;
}

.enhanced-table-root {
    width: 100%;
}

.enhanced-table-wrapper {
    overflow-x: auto;
}

.enhanced-table-toolbar-title {
    flex: 0 0 auto;
    display: inline-flex;
    width: 100%;
}

.enhance-table-toolbar-spacer {
    flex: 1 1 100%;
}

.table-header {
    font-weight: bold !important;
    font-size: 0.75rem !important;
    color: rgba(0, 0, 0, 0.54) !important;
}

.table-row-yes {
    color: #187900;
}

.table-row-no {
    color: #77777760;
}

.sub-action-container.MuiGrid-item {
    border: 5px;
    border-color: #777777;
    border-width: 5px;
    border-style: solid;
}

/*
* SNACKBAR
*/

.snackbar-info {
    display: flex !important;
    align-items: center !important;
}

.snackbar-close-icon {
    padding: 2px !important;
}

.snackbar-icon {
    margin: 0.6rem !important;
}

.snackbar-success {
    background: #4BB543 !important;
    padding: 5px !important;
}

.snackbar-error {
    background: #ff0033 !important;
    padding: 5px !important;
}

/*
* LOADING SPINNER
*/

.loading-modal {
    padding: 30px;
}

.loading-indeterminate {
    flex-grow: 1;
}

.spinner-indeterminate {
    margin: 50px;
    width: 100%;
}

/*
* TIMEOUT/LOGOUT MESSAGE
*/

.logout-message {
    text-align: center;
    font-size: 2em;
}

/*
* BUTTONS
*/

.copy-to-clipboard-btn {
    margin-left: 20px !important;
}

/*
* User dropdown info
*/

.drop-down-user-small {
    font-size: small;
}


/*
* MISC
*/

.margin20 {
    margin: 20px;
}

.welcome-divider {
    margin-top: 20px !important;
}

.details-grid {
    overflow: auto;
    padding: 20px;
}

.bread-crumbs div p,
.bread-crumbs div i,
.bread-crumbs div,
.bread-crumbs h1 {
    display: inline-block;
    margin-left: 5px;
    padding: 10px 0px 10px 0px;
}


.page-not-found-screen {
    margin-top: 100px;
}

/*
* this is a fix for the animated auto-completes where the invisible legend hides the top border
*/
fieldset legend {
    width: auto;
}
.date-range {
    padding-top: 15px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    
   
}
.past-data {
    margin-top: 50px;
    position: relative;
}

.customTooltip {
    background: rgba(255, 255, 255, 0.829);
    color: #333;
    box-shadow: 0 3px 14px rgb(0 0 0 / 40%);
    padding: 12px;
    text-align: left;
    border-radius: 12px;

    .label {
        font-weight: 500;
        color:#4b4a4a 
    }

    .desc {
        font-size: small;
    }
}

.current-graph-container {
    width: 100%;
    height: 320px;
    margin-bottom: 10px;
    margin-top: 10px;
    
}
.past-graph-container {
    width: 100%;
    height: 500px;
    margin-bottom: 140px;
}

.date-pod-selector {
    display: flex;
    flex-direction: row;
}

/*
* SMALL SCREEN SIZES
*
* Since we are not really expecting users to use small screens anyway, taking a simple approach to remove
* unnecessary components if the screen width is less that 1026px
* It probably wont look perfect, but it will be functional/accessible
*/


/* if the screen is small, we can hide non important things (such as the ABS logo) */

@media only screen and (max-width: 1026px) {

    #absLogo,
    .page-footer {
        display: none;
    }

    .table-search-box {
        width: 100%;
    }

    .MuiToolbar-root {
        display: block;
        flex-wrap: wrap;
        flex-direction: column;
    }
}
